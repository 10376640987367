import React from 'react';
import PropTypes from 'prop-types';

import { Layout, SEO } from 'components';

const NotFoundPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="Page not found" lang={locale} keywords={[]} location={{}} />

    <h1 className='section-title mt-50 mb-50'>Not found</h1>
    <p className='t-center mb-90'>It looks like nothing was found at this location.</p>
  </Layout>
);

NotFoundPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default NotFoundPage;
